<template>
	<page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Multiple Selection Acceptance" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <table-layout ref="table-layout"
                    :fields="table.fields"
                    :filters="table.filters"
										:filters-options="{ visible: true, collapsed: false, cols: 3 }"
                    :items="table.items"
                    :loading="table.loading"
                    :is-searchable="true"
                    @mounted="table = $event"
                    @updated="table = $event">

				<template #filters>
					<b-form-group label="Instrument" label-for="filters-instrument">
						<v-select id="filters-instrument"
											v-model="table.filters.instrument.value"
                      :loading="filters.instruments.loading"
											:options="filters.instruments.items" label="name"
											:reduce="option => option.id"
											:searchable="true"
											:clearable="true"
											:select-on-tab="true"
											class="font-small-3"
						/>
					</b-form-group>
					<b-form-group label="Ensemble" label-for="filters-ensemble">
						<v-select id="filters-ensemble"
											v-model="table.filters.ensemble.value"
                      :loading="filters.ensembles.loading"
											:options="filters.ensembles.items" label="name"
											:reduce="option => option.id"
											:searchable="true"
											:clearable="true"
											:select-on-tab="true"
											class="font-small-3"
						/>
					</b-form-group>
					<b-form-group label="School" label-for="filters-school">
						<v-select id="filters-school"
											v-model="table.filters.school.value"
                      :loading="filters.schools.loading"
											:options="filters.schools.items" label="name"
											:reduce="option => option.id"
											:filter="filterSchools"
											searchable clearable select-on-tab
											class="font-small-3">
							<template #option="{ name }">
                  <h6 class="mb-0 font-small-3">{{ name.legal }}</h6>
                  <small class="text-muted">{{ name.popular }}</small>
							</template>
              <template #selected-option="{ name }">
                <h6 class="mb-0 font-small-3">{{ name.legal }}</h6>
              </template>
						</v-select>
					</b-form-group>
				</template>


				<template #row-details="{ item }">
					<b-table-simple class="d-print-none">
						<b-thead>
							<b-tr>
								<b-th class="col-instrument">Instrument</b-th>
								<b-th class="col-ensemble">Ensemble</b-th>
								<b-th class="col-part">Part</b-th>
								<b-th class="col-score">Score</b-th>
								<b-th class="col-local">Local Rank</b-th>
								<b-th class="col-state">State Rank</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="selection in item.selections" :key="selection.id">
								<b-td class="col-student">
									<b-media vertical-align="center" no-body>
										<b-media-aside>
											<b-avatar variant="secondary" size="2.5em">
												<font-awesome-icon icon="fas fa-clipboard"></font-awesome-icon>
											</b-avatar>
										</b-media-aside>
										<b-media-body class="align-self-center">
                      <span v-if="hasValue(selection, 'instrument')">{{ selection.instrument.name }}</span>
                      <span v-else class="text-danger">No Instrument</span>
										</b-media-body>
									</b-media>
								</b-td>
								<b-td class="col-ensemble">
                  <span v-if="hasValue(selection, 'ensemble')">{{ selection.ensemble.name }}</span>
                  <span v-else class="text-danger">No Ensemble</span>
								</b-td>
								<b-td class="col-part">
									{{ selection.part }}
								</b-td>
								<b-td class="col-score">
									{{ selection.ranking.score }}
								</b-td>
								<b-td class="col-local">
									{{ selection.ranking.local }}
								</b-td>
								<b-td class="col-local">
									{{ selection.ranking.state }}
								</b-td>

							</b-tr>
						</b-tbody>
					</b-table-simple>
				</template>

				<template #cell(student)="{data}">
					<b-media vertical-align="center" no-body>
						<b-media-aside>
							<b-avatar size="2.5em"
												variant="primary" badge-variant="primary"
												button @click="data.toggleDetails">
								<font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
								<template #badge>
									<b-icon :icon="data.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
								</template>
							</b-avatar>
						</b-media-aside>
						<b-media-body class="align-self-center">
							<b-link :to="{ name: 'all-state-student', params: { id: data.item.student.id } }" class="font-weight-bold d-block text-nowrap">
								{{ data.item.student.name.first }} {{ data.item.student.name.last }}
							</b-link>
              <template>
                <small v-if="hasValue(data, 'data.item.school.name')" class="d-block">
                  {{ data.item.school.name.legal }}
                </small>
                <small v-else class="text-danger">No School</small>
              </template>

						</b-media-body>
					</b-media>
				</template>

				<template #cell(school)="{data}">
          <template v-if="hasValue(data, 'item.school.name')">
            <span>
              {{ data.item.school.name.legal }}
            </span>
            <small class="d-block">
              {{ data.item.school.name.popular }}
            </small>
          </template>
          <template v-else>
            <span class="text-danger">
              No School
            </span>
          </template>
				</template>

      </table-layout>
    </template>
    <template #debug>
      <debug>
        {{ selections }}
      </debug>
			<debug>
				{{ filters }}
			</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import vSelect from 'vue-select';
import {API, graphqlOperation} from 'aws-amplify';
import {listSelections} from './queries/multiple-selection-acceptance';
import Fuse from 'fuse.js';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
	name: 'MultipleSelectionAcceptance',
	components: {
		vSelect,
		TableLayout,
		PageLayout
	},
  mixins: [lodashMixin, settingsMixin],
	data() {
		return {
			selections: {
				items: [],
				loading: true
			},
			table: {
				loading: true,
				fields: [
					{
						key: 'student',
						subKeys: ['student.name.first', 'student.name.last'],
						label: 'Name',
						sortable: true,
						filterable: true,
						visible: true,
						tdClass: 'align-middle'
					},
					{
						key: 'school',
						subKeys: ['school.name.legal', 'school.name.popular'],
						label: 'School',
						sortable: true,
						filterable: true,
						visible: true,
						tdClass: 'align-middle'
					},
					{
						key: 'selections.length',
						label: 'Accepted Selections',
						sortable: true,
						filterable: true,
						visible: true,
						tdClass: 'align-middle'
					},
				],
				items: [],
				filters: {
					instrument: { key: 'selections.instrument.id', value: null },
					ensemble: { key: 'selections.ensemble.id', value: null },
					school: { key: 'school.id', value: null },
				},
			},
			filters: {
				instruments: {
          items: [],
          loading: true,
        },
				ensembles: {
          items: [],
          loading: true,
        },
				schools: {
          items: [],
          loading: true,
        },
			}
		}
	},
	async mounted() {
    this.$refs.layout.state.loading = false
		await this.listSelections()
	},
	methods: {
    async refresh() {
      this.table.loading = true
      await this.listSelections()
      if(this.table.items.length === 0) {
        this.table.filters.instrument.value = null
        this.table.filters.ensemble.value = null
        this.table.filters.school.value = null
      }
    },
		async listSelections(nextToken, pagedItems) {
        this.table.loading = true
        this.filters.instruments.loading = true
        this.filters.ensembles.loading = true
        this.filters.schools.loading = true

				const items = pagedItems || []
				const input = {
          filter: {
            accepted: { eq: true },
            createdAt: {
              between: [
                this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
              ]
            },
          },
          limit: 500,
          nextToken: nextToken
        }

				const response = await API.graphql(graphqlOperation(listSelections, input));
				items.push(...response.data.listSelections.items);

				if(response.data.listSelections.nextToken) {
						await this.listSelections(response.data.listSelections.nextToken, items)
				}
				else {
						this.selections.items = items
						this.selections.loading = false

						this.table.items = this.processSelections(items)
						this.table.loading = false
				}
		},

		processSelections(selections) {
			const studentsMap = {};
			const instrumentsMap = {};
			const ensemblesMap = {};
			const schoolsMap = {};

			selections.forEach((selection) => {
				if (selection.accepted) {
					const studentId = selection?.application?.student?.id;
					const student = {
						id: studentId,
						name: selection?.application?.student?.name
					};
					const school = selection?.application?.student?.school ? {
						id: selection?.application?.student?.school?.id,
						name: selection?.application?.student?.school?.name
					} : null;

					const acceptedSelection = {
						accepted: true,
						part: selection.part,
						ensemble: selection?.ensemble,
						instrument: selection?.application?.instrument,
						festival: selection?.application?.festival,
						ranking: {
							score: selection?.application?.ranking?.score,
							local: selection?.application?.ranking?.local,
							state: selection?.ranking?.state
						}
					};

					if (studentsMap[studentId]) {
						studentsMap[studentId].selections.push(acceptedSelection);
					}
					else {
						studentsMap[studentId] = {
								student: student,
								school: school,
								selections: [acceptedSelection],
						};
					}

					// Add unique instruments, ensembles, and schools to their respective maps
          if(selection?.application?.instrument?.id) {
            instrumentsMap[selection?.application?.instrument?.id] = {
              id: selection?.application?.instrument?.id,
              name: selection?.application?.instrument?.name,
            };
          }

          if(selection?.ensemble?.id) {
            ensemblesMap[selection?.ensemble?.id] = {
              id: selection?.ensemble?.id,
              name: selection?.ensemble?.name,
            };
          }

          if(selection?.application?.student?.school?.id) {
            schoolsMap[selection?.application?.student?.school?.id] = {
              id: selection?.application?.student?.school?.id,
              name: selection?.application?.student?.school?.name,
            };
          }
				}
			});

			// Populate filters with unique values
			this.filters.instruments.items = Object.values(instrumentsMap).sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
      this.filters.instruments.loading = false

			this.filters.ensembles.items = Object.values(ensemblesMap).sort((a, b) => a?.name?.localeCompare(b?.name));
      this.filters.ensembles.loading = false

			this.filters.schools.items = Object.values(schoolsMap).sort((a, b) => a?.name?.legal?.localeCompare(b?.name?.legal));
      this.filters.schools.loading = false

			// Convert the students object to an array
			return Object.values(studentsMap).filter((student) => student?.selections?.length > 1);
		},
		filterSchools(options, search) {
			const fuse = new Fuse(options, {
				keys: ['name.legal', 'name.popular'],
				shouldSort: true,
			})
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
		},
	},
}
</script>

<style>


.table.b-table > tbody > tr.b-table-details > td {
	border-top: none !important;
	padding: 0!important;
}

.b-table-details > td > table > tbody > tr > td {
	border-top: 0!important;
}
</style>
