// eslint-disable-next-line import/prefer-default-export
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                ranking {
                    state
                }
                part
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    student {
                        id
                        name {
                            first
                            last
                        }
                        school {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                    }
                    ranking {
                        score
                        local
                    }
                    festival {
                        id
                        name
                    }
                    instrument {
                        id
                        name
                        order
                    }
                }
                createdAt
                updatedAt
                selectionEnsembleId
                selectionApplicationId
            }
            nextToken
        }
    }
`;
